export const TestDev = false;


export const config= (status) => {

  // !important: update {initialState} in cup3State.js
  // 2023 : 386
  // 2024 : 429
  // 2025 : 474 
  
  return {
      URL: TestDev ? "https://m6f.c98.myftpupload.com/" : "https://389.785.myftpupload.com/",
      API_URL: TestDev ? "https://m6f.c98.myftpupload.com/wp-json/wp/v2" : "https://389.785.myftpupload.com/wp-json/wp/v2",
      about: "/aboutus?categories=384&_embed",
      FAQ: "/aboutus?categories=404",
      news: "/articles?_embed&per_page=50",
      blog: "/posts?_embed&per_page=50",
      tags: "/tags?per_page=30",
      teams: `/teams?season=`,
      drivers: `/drivers?per_page=50&season=`,
      legalsPage: "/aboutus?categories=385",
      rounds: `/rounds?season=`,
      stream: status == "production"?'/aboutus?categories=407':'/aboutus?categories=406',
      kayak: "/kayak/",
      partners: "/widgets?sidebar=sponsor-list",
      season:"/season",
  }
}