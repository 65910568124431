import axios from "axios";
import { convertToSlug } from "../../utils/stringUtils";
import { config } from "../../utils/configAPI";

const conf = config("production")  //production

// Set Loading
export const setLoading = (dispatch, status) =>
  dispatch({ type: "SET_LOADING", payload: status });

// Set Error
export const setError = (dispatch, error) =>
  dispatch({
    type: "SET_ERROR",
    payload: { error: error.status, message: error.message },
});

export const getAbout = async (dispatch, state) => {
  if (state.pages.about) return;

  // do fetch
  const date = new Date();
  await axios
    .get(`${conf.API_URL}${conf.about}`)
    .then((res) => {
      const actualDate = new Date();
      console.log("time response about",actualDate-date);
      const result = res.data.sort((a, b) => a.menu_order - b.menu_order);

      // set user info
      dispatch({
        type: "SET_ABOUT",
        payload: result,
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
};

export const getYear = async (dispatch, state) => {
  if(state.year) return;
  await axios
    .get(`${conf.API_URL}/pages/16171?_fields=acf`)
    .then((res) => {
      const result = res.data.acf.active_season;
      dispatch({
        type: "SET_YEAR",
        payload: result,
      });
    })
    .catch((error) => {
      console.log(error);
    });
}

export const getStreamSettings = async (dispatch, state) => {
  if(state.livetrack) return;
  await axios
    .get(`${conf.API_URL}/pages/16171?_fields=acf`)
    .then((res) => {
      const result = res.data.acf.force_livetrack;
      dispatch({
        type: "SET_FORCELIVETRACK",
        payload: result,
      });
    })
    .catch((error) => {
      console.log(error);
    });
}

export const getActualSeason = async (dispatch, state,season) => {
  if(state.actualSeason) return;
  dispatch({
    type: "SET_SEASON",
    payload: season,
  });
}

export const getSeasons = async (dispatch, state) => {
  if(state.season) return;
  await axios
  .get(`${conf.API_URL}${conf.season}`)
  .then((res) => {
    const result = res.data;
    dispatch({
      type: "SET_SEASONS",
      payload: result,
    });
  })
  .catch((error) => {
    console.log(error);
  });
}

export const getFAQS = async (dispatch, state) => {
  if (state.pages.faqs) return;
  // do fetch
  const date = new Date();
  await axios
    .get(`${conf.API_URL}/aboutus?categories=404`)
    .then((res) => {
      const actualDate = new Date();
      console.log("time response faq",actualDate-date);

      // set user info
      dispatch({
        type: "SET_FAQS",
        payload: JSON.parse(decodeURIComponent(res.data[0].meta.faqs)),
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
};

export const getNews = async (dispatch, state) => {
  if (state.pages.news) return;
  setLoading(dispatch, true);

  // do fetch
  const date = new Date();
  await axios
    .get(`${conf.API_URL}${conf.news}`)
    .then((res) => {
      const actualDate = new Date();
      console.log("time response news",actualDate-date);
      const result = res.data.map((item) => {
        return {
          id: item.id,
          title: item.title,
          date: item.date,
          subtitle: item.subTitle,
          slug: item.slug,
          image: item.featured_image_url,
          category: item._embedded["wp:term"][1],
          description: item.content,
          positionImage: item.position,
        };
      });
      // set user info
      dispatch({
        type: "SET_NEWS",
        payload: result,
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
};

export const getBlogs = async (dispatch, state) => {
  if (state.pages.blogs) return;
  setLoading(dispatch, true);

  // do fetch
  const date = new Date();
  await axios
    .get(`${conf.API_URL}${conf.blog}`)
    .then((res) => {
      const actualDate = new Date();
      console.log("time response blogs",actualDate-date);
      const result = res.data.map((item) => {
        return {
          id: item.id,
          title: String(item["title"]["rendered"]),
          date: item.date,
          subtitle: String(item["excerpt"]["rendered"]),
          slug: item.slug,
          image: String(item._embedded["wp:featuredmedia"][0]["source_url"]),
          category: item._embedded["wp:term"][1],
          description: item.content,
          metadata: item["acf"],
        };
      });
      // set user info
      dispatch({
        type: "SET_BLOGS",
        payload: result,
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
};

export const getTags = async (dispatch, state) => {
  if (state.pages?.news?.tags) return;
  setLoading(dispatch, true);

  // do fetch
  await axios
    .get(`${conf.API_URL}${conf.tags}`)
    .then((res) => {
      const result = res.data;

      // set user info
      dispatch({
        type: "SET_TAGS",
        payload: result,
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
};

export const getTeams = async (dispatch, state,year) => {
  if (state.pages.teams?.[year]) return;

  // do fetch
  const date = new Date();
  await axios
    .get(`${conf.API_URL}${conf.teams}${year}`)
    .then((res) => {
      const actualDate = new Date();
      // console.log("time response teams",actualDate-date);
      const filterData = res.data.map((item) => {
        return {
          "teamId": item["id"],
          "teamName": item["team-full-name"],
          "teamShortName": item["team-short-name"],
          "teamDescription": item.content.rendered,
          "teamImage": JSON.parse(decodeURIComponent(item["team-logo"])),
          "teamColor": item["team-color-primary"],
          "car-image": item["car-image"]?JSON.parse(decodeURIComponent(item["car-image"])):item["car-image"],
          "base-city": item["base-city"],
          "country": item["country"],
          "debut": item["debut-year"],
          "teamSeasonLineup":JSON.parse(decodeURIComponent(item["team-season-lineup"])),
          "acf": item["acf"],
          "socialIcons": [
            {
              "logo": "facebook",
              "url": item["facebook"],
            },
            {
              "logo": "instagram",
              "url": item["instagram"],
            },
            {
              "logo": "twitter",
              "url": item["twitter"],
            },
            {
              "logo": "youtube",
              "url": item["youtube"],
            },
            {
              "logo": "linkedin",
              "url": item["linkedin"],
            },
            {
              "logo": "website",
              "url": item["website"],
            },
          ],
          "drivers": JSON.parse(decodeURIComponent(item["team-drivers"])),
          "teamPrincipal": item["team-principal"],
          "galleryTag": item["media-tag-id"]?item["media-tag-id"]:undefined,
          "status": item["status"],
        };
      });

      // set user info
      dispatch({
        type: "SET_TEAMS",
        payload: {[year]:filterData},
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
};

export const getDrivers = async (dispatch, state,year) => {
  if (state.pages?.drivers?.[year]) return;
  setLoading(dispatch, true);

  // do fetch
    const date = new Date();
    axios
    .get(`${conf.API_URL}${conf.drivers}${year}`)
    .then((res) => {
      const actualDate = new Date();
      // console.log("time response drivers",actualDate-date);
      const filterData = res.data.map((item) => {
        return {
          "driverId": item.id,
          "teamId": item["team-id"],
          "driverName": item["first-name"]+" "+item["last-name"],
          "driverNum": item["driver-number"],
          "description":item.content.rendered,
          "driverCardImage": item["card-image"] !== ""? JSON.parse(decodeURIComponent(item["card-image"])):undefined,
          "driverImage": item["driver-page-cover"] !== ""? JSON.parse(decodeURIComponent(item["driver-page-cover"])):undefined,
          "cardImage": item["card-image"] !== ""? JSON.parse(decodeURIComponent(item["card-image"])):undefined,
          "birthDate": item["date-of-birth"].split("T")[0],
          "height": item["height"],
          "weight": item["weight"],
          "country": item["country"],
          "rookies": item["rookies"],
          "disable": item["disable"],
          "acf": item["acf"],
          "socialIcons": [
            {
              "logo": "facebook",
              "url": item["facebook"],
            },
            {
              "logo": "instagram",
              "url": item["instagram"],
            },
            {
              "logo": "twitter",
              "url": item["twitter"],
            },
            {
              "logo": "youtube",
              "url": item["youtube"],
            },
            {
              "logo": "linkedin",
              "url": item["linkedin"],
            },
            {
              "logo": "website",
              "url": item["website"],
            },
          ],
          "status": item["status"],
        };
      });
      // set user info
      dispatch({
        type: "SET_DRIVERS",
        payload: {[year]:filterData},
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
};

export const getLegals = async (dispatch, state) => {
  if (state.pages.legals) return;
  setLoading(dispatch, true);

  // do fetch
  await axios
    .get(`${conf.API_URL}${conf.legalsPage}`)
    .then((res) => {
      const result = res.data.sort((a, b) => a.menu_order - b.menu_order);

      // set user info
      dispatch({
        type: "SET_LEGALS",
        payload: result,
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
};

export const getGallery = async (dispatch, state,teamTag) => {
  if(teamTag === undefined) return ;
  if (state.gallery[teamTag]) return;
  
  // do fetch
  const date = new Date();
  await axios
    .get(`${conf.API_URL}/media?media_tags=${teamTag}&per_page=50`)
    .then((res) => {
      const actualDate = new Date();
      console.log("time response gallery",actualDate-date);
      const result = res.data.map((item) => {
        return Object.keys(item.media_details).length > 0 ?item.media_details.sizes["full"].source_url:item.source_url;
      });
      // set user info
      dispatch({
        type: "SET_GALLERY",
        payload: result,
        teamTag: teamTag,
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
};

export const getRounds = async (dispatch, state,year) => {
  if (state.pages?.rounds?.[year] ) return;
  setLoading(dispatch, true);

  // do fetch
  const date = new Date();
    axios
    .get(`${conf.API_URL}${conf.rounds}${year}`)
    .then((res) => {
      const actualDate = new Date();
      // console.log("time response round",actualDate-date);
      // calculates the final points for each driver
      const sumById = {};
      res.data.forEach((round) => {
        round.rankDrivers.forEach((obj) => {
          const { driverId, total } = obj;
          if (!sumById[driverId]) {
            sumById[driverId] = 0;
          }
        sumById[driverId] += total;
        });
      });
      //calculates final points + tie points for sorting purposes
      const sumByIdTie = {};
      res.data.forEach((round) => {
        round.rankDrivers.forEach((obj) => {
          const { driverId, total, tie } = obj;
          if (!sumByIdTie[driverId]) {
            sumByIdTie[driverId] = 0;
          }
          sumByIdTie[driverId] += total + tie;
        });
      });
      
      let roundRookies = {};
      res.data.forEach((round) => {
        let rookiesSum = {};
        round["third-race"].forEach((obj) => {
          if(obj["rookie-points"] === undefined) return;
          if(obj["rookie-points"] === null) return;
          const driverId = obj["driver-id"];
          console.log("driverId", driverId)
          const rookie = obj["rookie-points"];
          if (!rookiesSum[driverId]) {
            rookiesSum[driverId] = {R1:0,R2:0,R3:0,total:0};
          }
        rookiesSum[driverId].R3 = rookie;
        rookiesSum[driverId].total = rookiesSum[driverId].R1 + rookiesSum[driverId].R2 + rookiesSum[driverId].R3;
        });
        //console.log("round[third-race]",round["third-race"])
        /* console.log("round[1-race]",round["first-race"])
        console.log("round[2-race]",round["second-race"]) */
        round["second-race"].forEach((obj) => {
          if(obj["rookie-points"] === undefined) return;
          if(obj["rookie-points"] === null) return;
          const driverId = obj["driver-id"];
          const rookie = obj["rookie-points"];
          if (!rookiesSum[driverId]) {
            rookiesSum[driverId] = {R1:0,R2:0,R3:0,total:0};
          }
        rookiesSum[driverId].R2 = Number(rookie);
        rookiesSum[driverId].total = rookiesSum[driverId].R1 + rookiesSum[driverId].R2 + rookiesSum[driverId].R3;
        });
        round["first-race"].forEach((obj) => {
          if(obj["rookie-points"] === undefined) return;
          if(obj["rookie-points"] === null) return;
          const driverId = obj["driver-id"];
          const rookie = obj["rookie-points"];
          if (!rookiesSum[driverId]) {
            rookiesSum[driverId] = {R1:0,R2:0,R3:0,total:0};
          }
        rookiesSum[driverId].R1 = Number(rookie);
        rookiesSum[driverId].total = rookiesSum[driverId].R1 + rookiesSum[driverId].R2 + rookiesSum[driverId].R3;
        roundRookies[round["round-number"]] = rookiesSum;
        });
      });
      const keysOrder = Object.keys(sumById).sort((a, b) => sumByIdTie[b] - sumByIdTie[a])
      
      const sumStandingRookies = {};
      Object.keys(roundRookies).forEach((round) => {
        const rookies = roundRookies[round];
        Object.keys(rookies).forEach((rookie) => {
          if(!sumStandingRookies[rookie]) {
            sumStandingRookies[rookie] = 0;
          }
          sumStandingRookies[rookie] += rookies[rookie].total;
        })
      })
      const rookieStanding = Object.keys(sumStandingRookies).sort((a, b) => sumStandingRookies[b] - sumStandingRookies[a])
      
      const filterData = res.data.reverse().map((item) => {
        return {
          ...item,
          totalPoints: sumById,
          totalStanding: keysOrder,
          rookiesPoints: roundRookies[item["round-number"]],
          rookiesStanding: rookieStanding,
        };
      });
      dispatch({
        type: "SET_ROUNDS",
        payload: {[year]:filterData},
      });


      const CircuitData = res.data.map((item) => {
        return {
          name: item["circuit-display-name"],
          slug: convertToSlug(item["circuit-display-name"]),
          fullName: item["circuit-full-name"],
          country: item["country"],
          bigFlag: item["big-flag-icon"]?JSON.parse(decodeURIComponent(item["big-flag-icon"])):null,
          smallFlag: item["small-flag-icon"]?JSON.parse(decodeURIComponent(item["small-flag-icon"])):null,
          initialDate: item["date-start"],
          date: item["date-display-text"],
          circuitImageSmall: JSON.parse(decodeURIComponent(item["track-diagram-miniature"])),
          circuitImageLarge: JSON.parse(decodeURIComponent(item["track-diagram-large"])),
          circuitImageBack: JSON.parse(decodeURIComponent(item["track-background-image"])),
          round: item["round-number"],
          opened: item["opened-year"],
          length: item["circuit-lenght"] ,
          turns: item["number-of-turns"],
          timeZone: item["timezone"],
          lap_record: item["lap-record"],
          record_holder: item["lap-record-holder"],
          practice_horario_1: item["practice-horario-1"],
          qualy_horario_1: item["qualy-horario-1"],
          race_horario_1: item["race-horario-1"],
          show_practice_horario_2: item["show-practice-2"],
          practice_horario_2: item["practice-horario-2"],
          qualy_horario_2: item["qualy-horario-2"],
          race_horario_2: item["race-horario-2"],
          show_race_horario_3: item["show-race-horario-3"],
          race_horario_3: item["race-horario-3"],
          status : item["status"],
          acf: item["acf"],
        };
      });
      // set user info
      dispatch({
        type: "SET_CIRCUITS",
        payload: {[year]:CircuitData},
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
};

export const getStreams = async (dispatch, state) => {
  if (state.pages.legals) return;
  setLoading(dispatch, true);

  // do fetch
  await axios
    .get(`${conf.API_URL}${conf.stream}`)
    .then((res) => {
      let youtubeLink = ""
      switch (res.data[0].meta["r1-r2"]) {
        case "R1":
          youtubeLink = res.data[0].meta["live-youtube-r1"]
          break;
        case "R2":
          youtubeLink = res.data[0].meta["live-youtube-r2"]
          break;
        case "R3":
          youtubeLink = res.data[0].meta["live-youtube-r3"]
          break;
        default:
          youtubeLink = res.data[0].meta["live-youtube-r1"]
          break;
      }
      // set user info
      const live ={
        "live": res.data[0].meta["live"],
        "live-youtube": youtubeLink,
        "live-track": res.data[0].meta["live-track"],
      }
      dispatch({
        type: "SET_STREAMS",
        payload:  live,
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
};

export const getPartners = async (dispatch, state) => {
  if (state.partners) return;
  setLoading(dispatch, true);

  // do fetch
  await axios
    .get(`${conf.API_URL}${conf.partners}`)
    .then((res) => {
      const result = res.data;

      // set partners info
      dispatch({
        type: "SET_PARTNERS",
        payload: result,
      });
    })
    .catch((error) => {
      const result = error;

      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
}

export const getKayak = async (dispatch, state) => {
  if (state.kayak) return;
  setLoading(dispatch, true);
console.log("kayak api");
  // do fetch
  await axios
    .get(`${conf.URL}${conf.kayak}`)
    .then((res) => {

      const result = res.data;
      console.log("kayak",result);
      // set user info
      dispatch({
        type: "SET_KAYAK",
        payload: result,
      });
    })
    .catch((error) => {
      const result = error;
      console.log("kayak error",result);
      // set error if has any
      dispatch({
        type: "SET_ERROR",
        payload: {
          error: true,
          message: result,
        },
      });
    });
}