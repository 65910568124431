import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { cup3Context } from "./cup3Context";
import { reducer } from "./cup3Reducer";




export const useCup3 = () => {
  const { state, dispatch } = useContext(cup3Context);
  return [state, dispatch];
};

export const EuroCup3Context = ({ children }) => {

  const initialState = {
    pages:{},
    loading: true,
    error: false,
    message: "",
    gallery: {},
    year:2025,
    livetrack:false,
    // seasons:years,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <cup3Context.Provider
      value={{
        state: state,
        dispatch: dispatch
      }}
    >
      {children}
    </cup3Context.Provider>
  );
};
