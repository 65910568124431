import React from 'react'
import './formCRM.css'
import text from '../../../assets/data/staticText.json' 

export const FormCRM = () => {
	const {contact} = text

	function addAriaSelected531476000001358033(e) {
		var optionElem = e.target;
		var previousSelectedOption = optionElem.querySelector(
		"[aria-selected=true]"
		);
		if (previousSelectedOption) {
		previousSelectedOption.removeAttribute("aria-selected");
		}
		optionElem.querySelectorAll("option")[
		optionElem.selectedIndex
		].ariaSelected = "true";
	}
	function validateEmail531476000001358033() {
		var form = document.forms["WebToContacts531476000001358033"];
		var emailFld = form.querySelectorAll("[ftype=email]");
		var i;
		for (i = 0; i < emailFld.length; i++) {
		var emailVal = emailFld[i].value;
		if (emailVal.replace(/^\s+|\s+$/g, "").length != 0) {
			var atpos = emailVal.indexOf("@");
			var dotpos = emailVal.lastIndexOf(".");
			if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
			alert("Please enter a valid email address. ");
			emailFld[i].focus();
			return false;
			}
		}
		}
		return true;
	}

	function checkMandatory531476000001358033() {
		var mndFileds = new Array("First Name", "Last Name");
		var fldLangVal = new Array("Name", "Last\x20name");
		var i;
		for (i = 0; i < mndFileds.length; i++) {
		var fieldObj =
			document.forms["WebToContacts531476000001358033"][mndFileds[i]];
		if (fieldObj) {
			if (fieldObj.value.replace(/^\s+|\s+$/g, "").length == 0) {
			if (fieldObj.type == "file") {
				alert("Please select a file to upload.");
				fieldObj.focus();
				return false;
			}
			alert(fldLangVal[i] + " cannot be empty.");
			fieldObj.focus();
			return false;
			} else if (fieldObj.nodeName == "SELECT") {
			if (fieldObj.options[fieldObj.selectedIndex].value == "-None-") {
				alert(fldLangVal[i] + " cannot be none.");
				fieldObj.focus();
				return false;
			}
			} else if (fieldObj.type == "checkbox") {
			if (fieldObj.checked == false) {
				alert("Please accept  " + fldLangVal[i]);
				fieldObj.focus();
				return false;
			}
			}
			try {
			if (fieldObj.name == "Last Name") {
				const name = fieldObj.value;
			}
			} catch (e) {}
		}
		}
		if (!validateEmail531476000001358033()) {
		return false;
		}

		var urlparams = new URLSearchParams(window.location.search);
		if (urlparams.has("service") && urlparams.get("service") === "smarturl") {
		var webform = document.getElementById("webform531476000001358033");
		var service = urlparams.get("service");
		var smarturlfield = document.createElement("input");
		smarturlfield.setAttribute("type", "hidden");
		smarturlfield.setAttribute("value", service);
		smarturlfield.setAttribute("name", "service");
		webform.appendChild(smarturlfield);
		}

		document
		.querySelector(".crmWebToEntityForm .formsubmit")
		.setAttribute("disabled", true);
	}

	function tooltipShow531476000001358033(el) {
		var tooltip = el.nextElementSibling;
		var tooltipDisplay = tooltip.style.display;
		if (tooltipDisplay == "none") {
		var allTooltip = document.getElementsByClassName("zcwf_tooltip_over");
		var i;
		for (i = 0; i < allTooltip.length; i++) {
			allTooltip[i].style.display = "none";
		}
		tooltip.style.display = "block";
		} else {
		tooltip.style.display = "none";
		}
	}
	return (
		<>
			<div id='crmWebToEntityForm' class='zcwf_lblRight crmWebToEntityForm'>
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />

				<form id='webform531476000001358033' action='https://crm.zoho.eu/crm/WebToContactForm' name='WebToContacts531476000001358033' method='POST' onSubmit='javascript:document.charset="UTF-8"; return checkMandatory531476000001358033()' accept-charset='UTF-8'>
					<input type='text' style={{display:'none'}} name='xnQsjsdp' value='91f373ed493b7c4695151eff8161e61bb213740664cc301efd75edff295628f8'></input>
					<input type='hidden' name='zc_gad' id='zc_gad' value=''></input>
					<input type='text' style={{display:'none'}} name='xmIwtLD' value='93a2224f1eaeb779bb8deb444bc69de4ce2988cf284769714ae2310124f1a56f03cdd8771a0cd2233ce49e06e678ff79'></input>
					<input type='text' style={{display:'none'}} name='actionType' value='Q29udGFjdHM='></input>
					<input type='text' style={{display:'none'}} name='returnURL' value='https&#x3a;&#x2f;&#x2f;eurocup3.org'></input>
					{/* <!-- Do not remove this code. --> */}
					
					<div class="contact_title">
						<h1 style={{color: "white"}}>{contact.title}</h1>
						<p style={{color: "white"}}>{contact.description}<span style={{color: "black", fontWeight: 'bold'}}> {contact.email}</span></p>
					</div>

					
					
					<div class='zcwf_row wfrm_fld_dpNn'><div class='zcwf_col_lab' ><label for='CONTACTCF1'>Marca Asignada</label></div><div class='zcwf_col_fld'><select class='zcwf_col_fld_slt' role='combobox' aria-expanded='false' aria-haspopup='listbox' id='CONTACTCF1' onChange='addAriaSelected531476000001358033()' aria-required='false' aria-label='CONTACTCF1' name='CONTACTCF1' multiple >
						<option value='FOC'>FOC</option>
						<option value='H4R'>H4R</option>
						<option value='PHS'>PHS</option>
						<option selected value='TKA'>TKA</option>
					</select><div class='zcwf_col_help'></div></div></div><div class='zcwf_row wfrm_fld_dpNn'><div class='zcwf_col_lab'><label for='CONTACTCF11'>Sector Actividad</label></div><div class='zcwf_col_fld'><select class='zcwf_col_fld_slt' role='combobox' aria-expanded='false' aria-haspopup='listbox' id='CONTACTCF11' onChange='addAriaSelected531476000001358033()' aria-required='false' aria-label='CONTACTCF11' name='CONTACTCF11'  >
						<option value='-None-'>-None-</option>
						<option value='Banking'>Banking</option>
						<option value='Beauty'>Beauty</option>
						<option value='Constrution'>Constrution</option>
						<option value='Fashion'>Fashion</option>
						<option value='Food'>Food</option>
						<option value='Horeca'>Horeca</option>
						<option selected value='Motorsport'>Motorsport</option>
						<option value='Optics'>Optics</option>
						<option value='Other'>Other</option>
						<option value='Pharma'>Pharma</option>
						<option value='Press'>Press</option>
						<option value='Security'>Security</option>
						<option value='Shopping&#x20;Center'>Shopping Center</option>
						<option value='Transports'>Transports</option>
					</select><div class='zcwf_col_help'></div></div></div><div class='zcwf_row wfrm_fld_dpNn'><div class='zcwf_col_lab'><label for='CONTACTCF5'>Sub Sector Actividad</label></div><div class='zcwf_col_fld'><select class='zcwf_col_fld_slt' role='combobox' aria-expanded='false' aria-haspopup='listbox' id='CONTACTCF5' onChange='addAriaSelected531476000001358033()' aria-required='false' aria-label='CONTACTCF5' name='CONTACTCF5' multiple >
						<option value='Brand'>Brand</option>
						<option value='Dealer'>Dealer</option>
						<option selected value='F3'>F3</option>
						<option value='F4'>F4</option>
						<option value='KM'>KM</option>
						<option value='KM&#x20;Press'>KM Press</option>
						<option value='Media'>Media</option>
						<option value='Motorbikes'>Motorbikes</option>
						<option value='No&#x20;specific'>No specific</option>
						<option value='Partner'>Partner</option>
						<option value='Retail'>Retail</option>
						<option value='Sports'>Sports</option>
						<option value='Wholesale'>Wholesale</option>
					</select><div class='zcwf_col_help'></div></div></div><div class='zcwf_row'><div class='zcwf_col_lab'><label for='First_Name'>Name<span style={{color:'red'}}>*</span></label></div><div class='zcwf_col_fld'><input type='text' id='First_Name' aria-required='true' aria-label='First Name' name='First Name' aria-valuemax='40' maxlength='40'></input><div class='zcwf_col_help'></div></div></div><div class='zcwf_row'><div class='zcwf_col_lab'><label for='Last_Name'>Last name<span style={{color:'red'}}>*</span></label></div><div class='zcwf_col_fld'><input type='text' id='Last_Name' aria-required='true' aria-label='Last Name' name='Last Name' aria-valuemax='80' maxlength='80'></input><div class='zcwf_col_help'></div></div></div><div class='zcwf_row'><div class='zcwf_col_lab'><label for='Email'>Email</label></div><div class='zcwf_col_fld'><input type='text' ftype='email' autocomplete='false' id='Email' aria-required='false' aria-label='Email' name='Email' aria-valuemax='100' crmlabel='' maxlength='100'></input><div class='zcwf_col_help'></div></div></div><div class='zcwf_row wfrm_fld_dpNn'><div class='zcwf_col_lab'><label for='CONTACTCF6'>Language</label></div><div class='zcwf_col_fld'><select class='zcwf_col_fld_slt' role='combobox' aria-expanded='false' aria-haspopup='listbox' id='CONTACTCF6' onChange='addAriaSelected531476000001358033()' aria-required='false' aria-label='CONTACTCF6' name='CONTACTCF6'  >
						<option value='-None-'>-None-</option>
						<option selected value='English'>English</option>
						<option value='Spanish'>Spanish</option>
					</select>
						<div class='zcwf_col_help'></div>
					</div>
					</div>
					<div class='zcwf_row'>
						<div class='zcwf_col_lab'>
							<label for='Description'>Message</label>
						</div>
						<div class='zcwf_col_fld'>
							<textarea aria-multiline='true' id='Description' aria-required='false' aria-label='Description' name='Description'></textarea>
							<div class='zcwf_col_help'></div>
						</div>
					</div>
					<div class='zcwf_row'>
						<div class='zcwf_col_lab'></div>
						<div class='zcwf_col_fld col_flex'>
							<input type='submit' id='formsubmit' role='button' class='formsubmit zcwf_button' value='Submit' aria-label='Submit' title='Submit' />
							<input type='reset' class='zcwf_button' role='button' name='reset' value='Reset' aria-label='Reset' title='Reset' />
						</div>
					</div>
				</form>
			</div>

		</>
	)
}
